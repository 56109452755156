$(document).ready(function() {
	$("#testimonial-carousel").owlCarousel({
	  	autoPlay: false,
	  	slideSpeed: 700,
	  	paginationSpeed: 400,
	  	singleItem: true,
		lazyLoad: true,
		items: 1,
		autoHeight: true
	});

	$(".tap-to-call").each(function(){
		$(this).wrap('<a class="number-active" href="tel:'+$(this).text()+'" />')
	});


	$(".global-nav__button").on("click", function() {
		var navContainer = $(this).closest('.global-nav');

		$(navContainer).toggleClass('is-open');
	});

	var observer = lozad();
	observer.observe();
});